body {
	margin: 0;
	padding: 0;
}

 .App {
  text-align: center;
  font-family: monospace;
}

.App-logo {
  height: 180px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1170px) {
  #title {
    font-size: 0.9em;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .servicesCards{
    margin-bottom:5% !important;
  }

  #servicesContainerRow{
    
    margin-bottom: 140%;
  }

  .card-text{
    font-size: 0.85em;
  }

}